.dots {
  clear: both;
  display: flex;
  margin: 1rem 0 2rem;

  &--is-separator {
    margin: 8rem auto;
    justify-content: center;
  }
}

.dots__item {
  display: block;
  width: 0.9rem;
  height: 0.9rem;
  margin: 0 0.5rem;
}
