.entry-content {
  line-height: 1.5;

  p {
    margin-bottom: 1.5em;
  }

  ul {
    padding-left: 0.6em;
    margin-bottom: 1em;
  }

  li {
    margin-bottom: 0;
  }
}
