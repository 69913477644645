.article-default {
  margin-top: 3rem;

  @include bp-medium {
    margin-top: 7rem;
  }
}

.article-default__side-media {
  height: 100px;
  width: auto;

  @include bp-small {
    height: 150px;
  }

  @include bp-medium {
    height: auto;
    width: 100%;
  }
}
