// Utilities

/* Hide visually but not from screen readers */
// Allow important defs in utility class
.u-hide {
  left: -9999em !important;
  position: absolute !important;
}

.u-spacer {
  margin-bottom: 1rem;
}

.u-noscroll {
  overflow: hidden;
}

.u-block {
  display: block;
}

.u-oneliner {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.u-simple-link {
  color: inherit;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.u-hide\@small {
  @media only screen and (max-width: $bp-small - 1) {
    display: none;
  }
}

.u-hide\@medium {
  @media only screen and (max-width: $bp-medium - 1) {
    display: none;
  }
}
