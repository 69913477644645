@keyframes strokeFill {
  0% {
    stroke-dashoffset: 97;
  }
  100% {
    stroke-dashoffset: 182
  }
}

@keyframes slightlyScale {
  0% {
    transform: translateX(-50%) translateY(-50%) scale(0.9);
  }
  100% {
    transform: translateX(-50%) translateY(-50%) scale(1);
  }
}
