@charset "UTF-8";
/*
  Project: kazimierzpierzchala
  Author: thymikee
 */
/* Clearfix */
/* 480px */
/* 768px */
/* 1024px */
/* 1600px */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Prevent adjustments of font size after orientation changes in IE and iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/* HTML5 display definitions
       ========================================================================== */
/**
     * Add the correct display in IE <10.
     * Add the correct display in Edge, IE, and Firefox for `details` or `summary`.
     * Add the correct display in IE for `main`.
     */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

/**
     * Add the correct display in IE <10.
     */
audio,
canvas,
progress,
video {
  display: inline-block;
}

/**
     * Add the correct display and remove excess height in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  vertical-align: baseline;
}

/**
     * Add the correct display in IE <11, Safari <8, and Firefox <22.
     * 1. Add the correct display in IE.
     */
template,
[hidden] {
  display: none;
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0;
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox <40.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

/**
     * Add the correct font style in Android <4.4.
     */
dfn {
  font-style: italic;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  /* Set 1 unit of vertical rhythm on the top and bottom margins. */
  margin: 0.75em 0;
}

/**
     * Add the correct background and color in IE <10.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Remove the border on images inside links in IE <11.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1.5em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

/* Forms
       ========================================================================== */
/**
     * Known issues:
     * - By default, Chrome on OS X and Safari on OS X allow very limited styling of
     *   select, unless a border property is set. The default font weight on
     *   optgroup elements cannot safely be changed in Chrome on OSX and Safari on
     *   OS X.
     * - It is recommended that you do not style checkbox and radio inputs as
     *   Firefox's implementation does not respect box-sizing, padding, or width.
     * - Certain font size values applied to number inputs cause the cursor style of
     *   the decrement button to change from default to text.
     * - The search input is not fully stylable by default. In Chrome and Safari on
     *   OSX/iOS you can't control font, padding, border, or background. In Chrome
     *   and Safari on Windows you can't control border properly. It will apply
     *   border-width but will only show a border color (which cannot be controlled)
     *   for the outer 1px of that border. Applying -webkit-appearance: textfield
     *   addresses these issues without removing the benefits of search inputs (e.g.
     *   showing past searches). Safari (but not Chrome) will clip the cancel button
     *   on when it has padding (and textfield appearance).
     */
/**
     * 1. Change font properties to `inherit` in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     * 3. Address `font-family` inconsistency between `textarea` and other form in IE 7
     * 4. Improve appearance and consistency with IE 6/7.
     */
button,
input,
optgroup,
select,
textarea {
  font: inherit;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE <11.
     * 2. Remove the padding in IE <11.
     * 3. Remove excess padding in IE 7.
     *    Known issue: excess padding remains in IE 6.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on OS X.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * Correct the text style of placeholders in Chrome, Edge, and Safari.
     */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     * 4. Correct alignment displayed oddly in IE 6/7.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  white-space: normal;
  /* 1 */
  color: inherit;
  /* 2 */
  padding: 0;
  /* 3 */
}

/**
     * Restore the font weight unset by a previous rule.
     */
optgroup {
  font-weight: bold;
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

@keyframes strokeFill {
  0% {
    stroke-dashoffset: 97;
  }
  100% {
    stroke-dashoffset: 182;
  }
}

@keyframes slightlyScale {
  0% {
    transform: translateX(-50%) translateY(-50%) scale(0.9);
  }
  100% {
    transform: translateX(-50%) translateY(-50%) scale(1);
  }
}

/**
 * Set the global `box-sizing` state to `border-box`.
 *
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 * paulirish.com/2012/box-sizing-border-box-ftw
 */
html {
  box-sizing: border-box;
}

html *,
html *:before,
html *:after {
  box-sizing: inherit;
}

/**
 * As well as using normalize.css, it is often advantageous to remove all
 * margins from certain elements.
 */
body,
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
dl, dd, ol, ul,
form, fieldset, legend,
figure,
table, th, td, caption,
hr {
  margin: 0;
  padding: 0;
}

/**
 * Give a help cursor to elements that give extra info on `:hover`.
 */
abbr[title],
dfn[title] {
  cursor: help;
}

/**
 * Remove underlines from potentially troublesome elements.
 */
u,
ins {
  text-decoration: none;
}

/**
 * Apply faux underlines to inserted text via `border-bottom`.
 */
ins {
  border-bottom: 1px solid;
}

/**
 * Where `margin-bottom` is concerned,this value will be the same as the
 * base line-height. This allows us to keep a consistent vertical rhythm.
 * As per: csswizardry.com/2012/06/single-direction-margin-declarations
 */
h1, h2, h3, h4, h5, h6,
ul, ol, dl,
blockquote, p, address,
hr,
table,
fieldset, figure,
pre {
  margin-bottom: 1.3rem;
}

/**
 * Where `margin-left` is concerned we want to try and indent certain elements
 * by a consistent amount. Define that amount once,here.
 */
ul, ol, dd {
  margin-left: 1rem;
}

h1, h2 {
  color: #0e2f56;
  font-weight: 800;
  line-height: 1.375;
  margin: 0 0 0.5em;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 2rem;
}

hr {
  background-color: #747474;
  border: 0;
  display: block;
  height: 1px;
  margin: 1.3rem auto;
}

img {
  max-width: 100%;
}

a {
  color: #ff304f;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

li {
  margin-bottom: 0.5rem;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

html {
  background: #fff;
  color: #0e2f56;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-size: 62.5%;
}

body {
  font-size: 1.6em;
  font-weight: 300;
  line-height: 1.15;
  letter-spacing: 0.25px;
}

section {
  clear: both;
}

.container {
  max-width: 990px;
  padding: 0 15px;
  margin: 0 auto;
}

.o-list-bare {
  list-style: none;
  margin: 0;
  padding: 0;
}

.two-col::after {
  clear: both;
  content: "";
  display: table;
}

@media only screen and (min-width: 768px) {
  .two-col--v-center {
    display: flex;
    align-items: center;
  }
  .two-col--v-center::before {
    content: none;
  }
}

.two-col__column {
  clear: both;
  float: left;
  max-width: 100%;
}

@media only screen and (min-width: 768px) {
  .two-col__column {
    width: 50%;
    float: left;
    clear: none;
    padding: 0 4rem;
  }
}

.about .icon-list {
  font-size: 2rem;
}

.about .icon-list__item.u-oneliner {
  font-size: 1.6rem;
}

@media only screen and (min-width: 480px) {
  .about .icon-list__item.u-oneliner {
    font-size: 2rem;
  }
}

.about__subheading {
  margin: 2rem 0;
}

@media only screen and (min-width: 768px) {
  .about__subheading {
    margin: 4rem 0 3rem;
  }
}

.about__copy p {
  line-height: 1.5;
  margin-bottom: 1.5em;
}

.about__pic {
  position: relative;
  max-width: 34rem;
  margin: 0 auto;
}

@media only screen and (min-width: 768px) {
  .about__pic {
    margin: 0;
  }
}

.about__pic img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about__pic__colorbar--top {
  max-width: 24rem;
}

@media only screen and (min-width: 768px) {
  .about__pic__colorbar--top {
    margin-left: -3rem;
  }
}

.about__pic__colorbar--bottom {
  float: right;
  width: 5rem;
}

.about__pic__colorbar--bottom-last {
  float: right;
  width: 8rem;
}

@media only screen and (min-width: 768px) {
  .about__pic__colorbar--bottom-last {
    margin-right: -3rem;
  }
}

.article-default {
  margin-top: 3rem;
}

@media only screen and (min-width: 768px) {
  .article-default {
    margin-top: 7rem;
  }
}

.article-default__side-media {
  height: 100px;
  width: auto;
}

@media only screen and (min-width: 480px) {
  .article-default__side-media {
    height: 150px;
  }
}

@media only screen and (min-width: 768px) {
  .article-default__side-media {
    height: auto;
    width: 100%;
  }
}

.badge {
  position: relative;
  width: 9.4rem;
}

.badge::before {
  content: "";
  display: block;
  padding-bottom: 120%;
}

.badge__shield {
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  max-height: 100%;
  fill: transparent;
  stroke: currentColor;
  stroke-width: 1.4;
  stroke-linejoin: round;
  stroke-dasharray: 90;
  stroke-dashoffset: 97;
}

.badge__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40%;
  max-height: 100%;
  transform: translateX(-50%) translateY(-50%) scale(0.9);
}

.blockquote {
  color: #747474;
  margin: 5rem 0;
}

@media only screen and (min-width: 768px) {
  .blockquote {
    margin: 7rem 0;
  }
}

.blockquote__copy {
  font-size: 2rem;
  white-space: 0.5px;
  quotes: "“" "”" "‘" "’";
}

.blockquote__copy::before {
  content: open-quote;
  color: #0e2f56;
}

.blockquote__copy::after {
  content: close-quote;
  color: #ff304f;
}

@media only screen and (min-width: 768px) {
  .blockquote__copy {
    font-size: 2.4rem;
  }
}

.blockquote__cite {
  font-size: 1.4rem;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.blockquote__cite::before {
  content: "– ";
}

.bureau .icon-list {
  margin-left: 2rem;
}

@media only screen and (min-width: 768px) {
  .bureau .icon-list {
    margin-left: 4rem;
  }
}

.bureau__title {
  text-align: center;
}

.bureau__image {
  position: relative;
  overflow: hidden;
  margin: 1.5rem 0;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .bureau__image {
    margin: 4rem 0;
  }
}

.bureau__image::before {
  content: "";
  display: block;
  padding-bottom: 53%;
}

.bureau__image::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.8;
  mix-blend-mode: color;
  background: inherit;
}

.bureau__image:hover > img {
  opacity: 0.2;
}

.bureau__image:hover .bureau__image__overlay {
  visibility: visible;
  opacity: 1;
}

.bureau__image--red {
  background: #ff304f;
}

.bureau__image--blue {
  background: #118df0;
}

.bureau__image--navy {
  background: #0e2f56;
}

.bureau__image > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  transition: opacity .2s;
}

.bureau__image__overlay {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .2s, visibility .2s;
}

.colorbar {
  display: flex;
  width: 100%;
}

.colorbar .colorbar__item:nth-child(1) {
  flex: 1 0 50%;
}

.colorbar .colorbar__item:nth-child(2) {
  flex: 1 0 22%;
}

.colorbar .colorbar__item:nth-child(3) {
  flex: 1 0 28%;
}

.colorbar__item {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 1rem;
  font-weight: 400;
}

.colorbar__item--high {
  height: 6rem;
}

.colorbar__item-link {
  color: #fff;
}

.dots {
  clear: both;
  display: flex;
  margin: 1rem 0 2rem;
}

.dots--is-separator {
  margin: 8rem auto;
  justify-content: center;
}

.dots__item {
  display: block;
  width: 0.9rem;
  height: 0.9rem;
  margin: 0 0.5rem;
}

.entry-content {
  line-height: 1.5;
}

.entry-content p {
  margin-bottom: 1.5em;
}

.entry-content ul {
  padding-left: 0.6em;
  margin-bottom: 1em;
}

.entry-content li {
  margin-bottom: 0;
}

.footer-secondary {
  background: #f7f7f7;
  padding: 5rem 0;
  margin: 5rem 0;
}

.footer-secondary .insurance__happy {
  margin-bottom: 2rem;
}

@media only screen and (min-width: 768px) {
  .footer-secondary .insurance__happy {
    margin-bottom: 0;
  }
}

.footer {
  margin: 5rem 0 0;
}

.footer__logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 10rem 0;
}

.footer__logos__item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

@media only screen and (min-width: 480px) {
  .footer__logos__item {
    max-width: 150px;
  }
}

.footer__logos__item img {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 150px;
}

.footer__logos__item > * {
  padding: 1rem 2rem;
}

.header {
  padding: 2.5rem 0;
}

.header > .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo {
  display: block;
  width: 18rem;
}

.header__logo img {
  width: 100%;
}

.header__nav {
  display: none;
  flex: 1 1 50%;
}

@media only screen and (min-width: 768px) {
  .header__nav {
    display: block;
  }
}

.header__contact {
  display: flex;
  justify-content: flex-end;
}

.header__contact__item {
  padding: 1rem 1.2rem;
}

.header__contact__item > span {
  float: left;
}

.header__navlist {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

.header__navlist__item > a {
  display: block;
  padding: 1rem 1.2rem;
}

.header__mobile-phone {
  font-weight: bold;
  color: #ff304f;
  padding: 1rem;
  margin-right: -1rem;
}

@media only screen and (min-width: 768px) {
  .header__mobile-phone {
    display: none;
  }
}

.heading {
  font-weight: normal;
  letter-spacing: 0.5px;
}

.heading--center {
  text-align: center;
  margin-left: auto;
  margin-right: 0;
}

.heading--sm {
  font-size: 1.8rem;
}

@media only screen and (min-width: 768px) {
  .heading--sm {
    font-size: 2rem;
  }
}

.heading--md {
  font-size: 2rem;
}

@media only screen and (min-width: 768px) {
  .heading--md {
    font-size: 2.4rem;
  }
}

.heading--lg {
  font-size: 2.2rem;
}

@media only screen and (min-width: 768px) {
  .heading--lg {
    font-size: 3rem;
  }
}

.heading--section {
  text-align: center;
  margin-bottom: 3rem;
  margin-top: 3rem;
}

@media only screen and (min-width: 768px) {
  .heading--section {
    text-align: left;
    margin-left: 45%;
    margin-bottom: 9rem;
    margin-top: 9rem;
  }
}

.hero {
  padding: 3rem 0;
}

@media only screen and (min-width: 768px) {
  .hero {
    padding: 10rem 0;
  }
}

@media only screen and (min-width: 768px) {
  .hero > .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.hero__title {
  text-align: center;
  font-weight: normal;
  font-size: 2.4rem;
  line-height: 1.15;
  word-break: break-word;
  margin: 0;
}

@media only screen and (min-width: 768px) {
  .hero__title {
    font-size: 3.2rem;
    text-align: left;
  }
}

.hero__badges {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}

@media only screen and (min-width: 768px) {
  .hero__badges {
    margin-top: 0;
  }
}

.hero__badges .badge {
  margin: 0 1rem;
}

@media only screen and (min-width: 768px) {
  .hero__badges .badge {
    margin: 0 2rem;
  }
}

.hero__badges .badge:nth-child(1) .badge__shield {
  animation: strokeFill 1s 1.5s forwards;
}

.hero__badges .badge:nth-child(1) .badge__inner {
  animation: slightlyScale 2.5s 0.1s forwards;
}

.hero__badges .badge:nth-child(2) .badge__shield {
  animation: strokeFill 1.3s 1.7s forwards;
}

.hero__badges .badge:nth-child(2) .badge__inner {
  animation: slightlyScale 2.4s 0.2s forwards;
}

.hero__badges .badge:nth-child(3) .badge__shield {
  animation: strokeFill 1.1s 1.8s forwards;
}

.hero__badges .badge:nth-child(3) .badge__inner {
  animation: slightlyScale 2.3s 0.3s forwards;
}

.hero__rotator {
  display: block;
  color: #118df0;
  overflow: hidden;
  min-height: 1.15em;
}

@media only screen and (min-width: 1024px) {
  .hero__rotator {
    display: inline;
  }
}

.hero__rotator > span {
  position: absolute;
  opacity: 0;
}

@media only screen and (min-width: 1024px) {
  .hero__rotator > span {
    transition: opacity .15s;
  }
}

.hero__rotator > span.is-active {
  position: static;
  opacity: 1;
}

.icon-list {
  list-style: none;
  margin: 0;
  line-height: 1.5;
  letter-spacing: 0.25px;
}

@media only screen and (min-width: 768px) {
  .icon-list {
    font-size: 1.8rem;
  }
}

.icon-list--spread .icon-list__item {
  margin: 1.5rem 0;
}

.icon-list--spread .icon-list__item:last-child {
  margin-bottom: 0;
}

.icon-list__item {
  clear: both;
  overflow: hidden;
}

.icon-list__item--click {
  cursor: pointer;
}

.icon-list__item--click:hover {
  text-decoration: underline;
}

.icon-list__item__inner {
  display: block;
  padding-left: 1.5em;
}

.icon-list__item__icon {
  display: block;
  width: 0.8em;
  height: 1.5em;
  float: left;
  margin-right: 1rem;
}

.insurance {
  clear: both;
  margin: 2rem 0 5rem;
}

@media only screen and (min-width: 768px) {
  .insurance {
    margin: 4rem 0 10rem;
  }
}

@media only screen and (min-width: 768px) {
  .insurance__container {
    font-size: 2rem;
  }
}

.insurance__media {
  display: none;
}

@media only screen and (min-width: 768px) {
  .insurance__media {
    display: block;
    float: left;
    width: 37%;
    margin-right: 8%;
    margin-top: -4rem;
  }
}

.insurance__media img {
  min-height: 100px;
  max-width: 100%;
}

.insurance__content {
  flex: 1 1 55%;
}

@media only screen and (min-width: 768px) {
  .insurance__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.insurance__content > .heading {
  text-align: center;
  flex: 1 0 100%;
}

@media only screen and (min-width: 768px) {
  .insurance__content > .heading {
    text-align: left;
  }
}

.insurance__list {
  margin: 2rem 0;
  text-align: center;
  list-style: none;
}

@media only screen and (min-width: 768px) {
  .insurance__list {
    margin: 0 1rem 2rem;
    float: left;
    text-align: left;
  }
}

.insurance__list > li {
  margin: 0.5rem 0;
}

.insurance__list > li::before {
  content: "✔";
  font-size: 0.6em;
  margin-right: 0.6em;
  -webkit-font-smoothing: antialiased;
}

@media only screen and (min-width: 768px) {
  .insurance__list > li {
    margin: 0;
  }
}

.insurance__list a {
  display: inline-block;
  padding: 0.5rem 0;
}

@media only screen and (min-width: 768px) {
  .insurance__list a {
    padding: 0;
  }
}

.insurance__happy {
  min-width: 28%;
  text-align: center;
}

.insurance__happy svg {
  width: 6rem;
  height: 2rem;
}

.insurance__happy__strong {
  font-weight: normal;
  font-size: 2.4rem;
}

.map {
  position: relative;
  margin: 7rem 0;
  height: 250px;
  width: 100%;
  text-align: center;
}

.map__placeholder {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.map__message {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  font-size: 0.75em;
  line-height: 2;
}

@media only screen and (min-width: 1024px) {
  .map__message {
    display: none;
  }
}

.places__list {
  list-style: none;
  margin: 0;
}

@media only screen and (min-width: 768px) {
  .places__list {
    display: flex;
    justify-content: space-between;
  }
}

.places__list__item {
  margin-bottom: 3rem;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 768px) {
  .places__list__item {
    flex: 1;
    max-width: 30%;
  }
}

.u-color-red {
  color: #ff304f;
  fill: #ff304f;
}

.u-color-navy {
  color: #0e2f56;
  fill: #0e2f56;
}

.u-color-blue {
  color: #118df0;
  fill: #118df0;
}

.u-bg-red {
  background-color: #ff304f;
}

.u-bg-navy {
  background-color: #0e2f56;
}

.u-bg-blue {
  background-color: #118df0;
}

/* Hide visually but not from screen readers */
.u-hide {
  left: -9999em !important;
  position: absolute !important;
}

.u-spacer {
  margin-bottom: 1rem;
}

.u-noscroll {
  overflow: hidden;
}

.u-block {
  display: block;
}

.u-oneliner {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.u-simple-link {
  color: inherit;
  cursor: pointer;
}

.u-simple-link:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 479px) {
  .u-hide\@small {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .u-hide\@medium {
    display: none;
  }
}
