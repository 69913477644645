.badge {
  position: relative;
  width: 9.4rem;

  &::before {
    content: "";
    display: block;
    padding-bottom: 120%;
  }
}

.badge__shield {
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  max-height: 100%;
  fill: transparent;
  stroke: currentColor;
  stroke-width: 1.4;
  stroke-linejoin: round;
  stroke-dasharray: 90;
  stroke-dashoffset: 97;
}

.badge__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40%;
  max-height: 100%;
  transform: translateX(-50%) translateY(-50%) scale(0.9);
}
