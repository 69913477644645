// Headings
//------------------------------------------------------------------------------

h1, h2 {
  color: $color-text;
  font-weight: 800;
  line-height: 1.375;
  margin: 0 0 0.5em;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 2rem;
}
