.footer {
  margin: 5rem 0 0;
}

.footer__logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 10rem 0;
}

.footer__logos__item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;

  @include bp-small {
    max-width: 150px;
  }

  img {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 150px;
  }

  > * {
    padding: 1rem 2rem;
  }
}
