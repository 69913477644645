.insurance {
  clear: both;
  margin: 2rem 0 5rem;

  @include bp-medium {
    margin: 4rem 0 10rem;
  }
}

.insurance__container {
  @include bp-medium {
    font-size: 2rem;
  }
}

.insurance__media {
  display: none;

  @include bp-medium {
    display: block;
    float: left;
    width: 37%;
    margin-right: 8%;
    margin-top: -4rem;
  }

  img {
    min-height: 100px;
    max-width: 100%;
  }
}

.insurance__content {
  flex: 1 1 55%;

  @include bp-medium {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  > .heading {
    text-align: center;
    flex: 1 0 100%;

    @include bp-medium {
      text-align: left;
    }
  }
}

.insurance__list {
  margin: 2rem 0;
  text-align: center;
  list-style: none;

  @include bp-medium {
    margin: 0 1rem 2rem;
    float: left;
    text-align: left;
  }

  > li {
    margin: 0.5rem 0;

    &::before {
      content: "✔";
      font-size: 0.6em;
      margin-right: 0.6em;
      -webkit-font-smoothing: antialiased;
    }

    @include bp-medium {
      margin: 0;
    }
  }

  a {
    display: inline-block;
    padding: 0.5rem 0;

    @include bp-medium {
      padding: 0;
    }
  }
}

.insurance__happy {
  min-width: 28%;
  text-align: center;

  svg {
    width: 6rem;
    height: 2rem;
  }
}

.insurance__happy__strong {
  font-weight: normal;
  font-size: 2.4rem;
}
