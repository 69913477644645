.u-color-red {
  color: $color-red;
  fill: $color-red;
}

.u-color-navy {
  color: $color-navy;
  fill: $color-navy;
}

.u-color-blue {
  color: $color-blue;
  fill: $color-blue;
}

.u-bg-red {
  background-color: $color-red;
}

.u-bg-navy {
  background-color: $color-navy;
}

.u-bg-blue {
  background-color: $color-blue;
}
