.heading {
  font-weight: normal;
  letter-spacing: 0.5px;

  &--center {
    text-align: center;
    margin-left: auto;
    margin-right: 0;
  }

  &--sm {
    font-size: 1.8rem;

    @include bp-medium {
      font-size: 2rem;
    }
  }

  &--md {
    font-size: 2rem;

    @include bp-medium {
      font-size: 2.4rem;
    }
  }

  &--lg {
    font-size: 2.2rem;

    @include bp-medium {
      font-size: 3rem;
    }
  }

  &--section {
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 3rem;

    @include bp-medium {
      text-align: left;
      margin-left: 45%;
      margin-bottom: 9rem;
      margin-top: 9rem;
    }
  }
}
