.colorbar {
  display: flex;
  width: 100%;

  .colorbar__item {
    &:nth-child(1) {
      flex: 1 0 50%;
    }

    &:nth-child(2) {
      flex: 1 0 22%;
    }

    &:nth-child(3) {
      flex: 1 0 28%;
    }
  }
}

.colorbar__item {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 1rem;
  font-weight: 400;

  &--high {
    height: 6rem;
  }
}

.colorbar__item-link {
  color: $color-white;
}
