// Links

a {
  color: $color-link;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
