.header {
  padding: 2.5rem 0;

  > .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.header__logo {
  display: block;
  width: 18rem;

  img {
    width: 100%;
  }
}

.header__nav {
  display: none;
  flex: 1 1 50%;

  @include bp-medium {
    display: block;
  }
}

.header__contact {
  display: flex;
  justify-content: flex-end;
}

.header__contact__item {
  padding: 1rem 1.2rem;

  > span {
    float: left;
  }
}

.header__navlist {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

.header__navlist__item {
  > a {
    display: block;
    padding: 1rem 1.2rem;
  }
}

.header__mobile-phone {
  font-weight: bold;
  color: $color-red;
  padding: 1rem;
  margin-right: -1rem;

  @include bp-medium {
    display: none;
  }
}
