$a-stroke-delay: 1.5s;
$a-stroke-time: 1s;

.hero {
  padding: 3rem 0;

  @include bp-medium {
    padding: 10rem 0;
  }

  > .container {
    @include bp-medium {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.hero__title {
  text-align: center;
  font-weight: normal;
  font-size: 2.4rem;
  line-height: 1.15;
  word-break: break-word;
  margin: 0;

  @include bp-medium {
    font-size: 3.2rem;
    text-align: left;
  }
}

.hero__badges {
  display: flex;
  justify-content: center;
  margin-top: 5rem;

  @include bp-medium {
    margin-top: 0;
  }

  .badge {
    margin: 0 1rem;

    @include bp-medium {
      margin: 0 2rem;
    }

    &:nth-child(1) {
      .badge__shield {
        animation: strokeFill $a-stroke-time $a-stroke-delay forwards;
      }

      .badge__inner {
        animation: slightlyScale 2.5s 0.1s forwards;
      }
    }

    &:nth-child(2) {
      .badge__shield {
        animation: strokeFill $a-stroke-time + 0.3s $a-stroke-delay + 0.2s forwards;
      }

      .badge__inner {
        animation: slightlyScale 2.4s 0.2s forwards;
      }
    }

    &:nth-child(3) {
      .badge__shield {
        animation: strokeFill $a-stroke-time + 0.1s $a-stroke-delay + 0.3s forwards;
      }

      .badge__inner {
        animation: slightlyScale 2.3s 0.3s forwards;
      }
    }
  }
}

.hero__rotator {
  display: block;
  color: $color-blue;
  overflow: hidden;
  min-height: $line-height * 1em;

  @include bp-large {
    display: inline;
  }

  > span {
    position: absolute;
    opacity: 0;

    @include bp-large {
      transition: opacity .15s;
    }

    &.is-active {
      position: static;
      opacity: 1;
    }
  }
}
