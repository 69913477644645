// Page

html {
  background: $color-bg;
  color: $color-text;
  font-family: $font-sans;
  font-size: 62.5%;
}

body {
  font-size: 1.6em;
  font-weight: $font-weight;
  line-height: $line-height;
  letter-spacing: 0.25px;
}
